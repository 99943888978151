import { Link } from "gatsby";
import React from "react";
import Header from "../../components/Header";
import LinkButton from "../../components/LinkButton";
import ChevronRight from "../../icons/ChevronRight";
import { useGuideData } from "../../templates/GuideTemplate/hooks";

export default function GuidesHome() {
  const guides = useGuideData();

  return (
    <>
      <Header className="sticky" />
      <main className="layout-container">
        <h1 className="text-4xl md:text-5xl border-b border-gray py-4 mb-6">
          All guides
        </h1>
        <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {guides.map((guide) => (
            <li key={guide.slug}>
              <LinkButton href={guide.slug} fullWidth variant="gray">
                <span className="flex-grow">{guide.title}</span>
                <span className="flex-shrink-0">
                  <ChevronRight size={12} />
                </span>
              </LinkButton>
            </li>
          ))}
        </ul>
      </main>
    </>
  );
}
