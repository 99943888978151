import { graphql, useStaticQuery } from "gatsby";

export function useGuideData() {
  const guideData = useStaticQuery<{
    allMarkdownRemark: {
      edges: {
        node: {
          frontmatter: {
            slug: string;
            title: string;
            order: number;
            category: string;
          };
        };
      }[];
    };
  }>(graphql`
    {
      allMarkdownRemark {
        edges {
          node {
            frontmatter {
              slug
              title
              order
              category
            }
          }
        }
      }
    }
  `);

  return guideData.allMarkdownRemark.edges
    .map(
      ({
        node: {
          frontmatter: { title, slug, order, category },
        },
      }) => ({ title, slug, order, category })
    )
    .sort((a, b) => (a.order > b.order ? 1 : -1));
}
