import React from "react";
import { Link } from "gatsby";
import ChevronRightIcon from "../../icons/ChevronRight";
import Discord from "../../icons/Discord";
import LogoIcon from "../../icons/Logo";
import LinkButton from "../LinkButton";
import NavContent from "../NavContent";

export default function Header({
  className = "",
  isGuidesPage,
}: {
  className?: string;
  isGuidesPage?: boolean;
}) {
  return (
    <header className={`bg-black top-0 left-0 right-0 z-20 ${className}`}>
      <NavContent className={HEADER_HEIGHT} isGuidesPage={isGuidesPage}>
        <div className="hidden sm:block ml-10">
          <LinkButton
            href="https://app.lasagna.xyz"
            rel="noopener noreferrer"
            target="_blank"
          >
            <span className="mr-2.5">Launch app</span>
            <ChevronRightIcon />
          </LinkButton>
        </div>{" "}
      </NavContent>
    </header>
  );
}

export const HEADER_HEIGHT = "h-16 md:h-20";
