import React from "react";
import type { ReactNode } from "react";
import { Link } from "gatsby";

export default function LinkButton({
  children,
  target,
  rel,
  href,
  size,
  fullWidth,
  variant,
  className = "",
}: {
  children: ReactNode;
  target?: string;
  rel?: string;
  href: string;
  size?: "normal" | "large";
  variant?: "white" | "gray";
  fullWidth?: boolean;
  className?: string;
}) {
  const buttonStyles = generateButtonStyles({ size, fullWidth, variant });

  const containerStyles = `${buttonStyles.container} ${className}`;

  if (target) {
    return (
      <a className={containerStyles} target={target} rel={rel} href={href}>
        <span className={buttonStyles.body}>{children}</span>
        <span className={buttonStyles.background} />
      </a>
    );
  }

  return (
    <Link to={href} className={containerStyles}>
      <span className={buttonStyles.body}>{children}</span>
      <span className={buttonStyles.background} />
    </Link>
  );
}

const variants = {
  white: {
    body: "bg-white text-black group-hover:bg-primary",
    background: "bg-white group-hover:bg-primary",
  },
  gray: {
    body: "bg-gray text-white group-hover:bg-gray-light",
    background: "bg-gray group-hover:bg-gray-light",
  },
};

const sizes = {
  normal: "text-base py-2 px-4",
  large: `text-lg py-2 px-4 md:py-3 md:px-5`,
};

function generateButtonStyles({
  variant = "white",
  size = "normal",
  fullWidth,
}: ButtonStyleArgs = {}) {
  return {
    container: `relative group inline-block cursor-pointer font-secondary ${
      fullWidth ? "w-full" : ""
    }`,
    body: `z-10 relative translate-x-0 translate-y-0 flex items-center justify-center shadow-layer-button transition transform will-change-transform group-hover:translate-x-0.5 group-hover:-translate-y-0.5 ${variants[variant].body} ${sizes[size]}`,
    background: `absolute z-0 inset-0 transform -translate-x-1 translate-y-1 transition-colors ${variants[variant].background}`,
  };
}

interface ButtonStyleArgs {
  variant?: "white" | "gray";
  size?: "normal" | "large";
  fullWidth?: boolean;
}
