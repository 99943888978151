import React from "react";

export default function ChevronRight({ size = 8 }: { size?: number }) {
  return (
    <svg
      width={size}
      height={(size * 12) / 8}
      viewBox="0 0 8 12"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2.53349 1.47061L2.00411 0.939342L0.941564 1.99812L1.47095 2.52939L2.53349 1.47061ZM6 6.012L6.52953 6.54313L7.06051 6.01374L6.53127 5.48261L6 6.012ZM1.47047 9.46887L0.939341 9.99841L1.99841 11.0607L2.52953 10.5311L1.47047 9.46887ZM1.47095 2.52939L5.46873 6.54139L6.53127 5.48261L2.53349 1.47061L1.47095 2.52939ZM5.47047 5.48087L1.47047 9.46887L2.52953 10.5311L6.52953 6.54313L5.47047 5.48087Z" />
    </svg>
  );
}
