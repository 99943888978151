import React from "react";
import { Link } from "gatsby";
import DiscordIcon from "../icons/Discord";
import LogoIcon from "../icons/Logo";
import TwitterIcon from "../icons/Twitter";

export default function NavContent({
  children,
  className = "",
  isGuidesPage,
}: {
  children?: React.ReactNode;
  className?: string;
  isGuidesPage?: boolean;
}) {
  return (
    <div
      className={`flex items-center justify-between layout-container ${className}`}
    >
      <Link to="/" className="w-32 sm:w-36">
        <LogoIcon />
      </Link>
      <div className="flex items-center pl-2">
        <a
          title="Open lasagna Discord app"
          className="text-white hover:text-discord transition-colors flex items-center"
          target="_blank"
          rel="noopener noreferrer"
          href="https://discord.gg/h2UFpW3hHP"
        >
          <DiscordIcon />
        </a>
        <a
          title="Open lasagna Twitter page"
          className="text-white hover:text-twitter transition-colors flex items-center ml-6 md:ml-8"
          target="_blank"
          rel="noopener noreferrer"
          href="https://twitter.com/lasagnaxyz"
        >
          <TwitterIcon />
        </a>
        <Link
          className={`font-secondary ml-6 md:ml-8 transition-colors ${
            isGuidesPage ? "text-primary" : "text-white hover:text-primary"
          }`}
          to="/guides/welcome"
        >
          Guides
        </Link>
        {children}
      </div>
    </div>
  );
}
